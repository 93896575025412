:root {
  --PurpleColor: #596087;
  --DarkPurpleColor: #596087;
  --DarkestPurple: #3d1e72;
  --LightPurpleColor: #c3c6eb;
  --LightestPurpleColor: #c3c6eb;
  --LightGrey: #f7f7f7;
  --LighterGrey: #f5f5f5;
  --DarkGrey: #333333;
  --White:#ffffff;
  --DarkBlue:#06034B;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: var(--PurpleColor) !important;
}

/* Button Styles */
.ui.purple.button {
  background-color: var(--PurpleColor) !important;
}

/* Login UI */
.loginWrapper {
  width: 550px !important;
  height: 100% !important;

  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  background-color: var(--LightGrey) !important;
}

.loginWrapper .loginForm {
  background-color: var(--LightGrey) !important;
}

.loginWrapper .ui.segment {
  border-radius: 0px !important;
  height: 100%;
}

.btnForgotPassword {
    border: 0 !important;
    color: var(--DarkPurpleColor) !important;
    background: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btnForgotPassword:hover {
    text-decoration: underline !important;
}

/* Referral UI */
.referralBackground{
  background-color: var(--DarkBlue) !important;
  width: 100% !important;
  float: left;
  height: 100%!important;
  position: absolute;
}


.referralWrapper {
  width: 550px !important;
  height: 100% !important;

  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  background-color: var(--LightGrey) !important;
}

.referralWrapper .loginForm {
  background-color: var(--LightGrey) !important;
}

.referralWrapper .ui.segment {
  border-radius: 0px !important;
  height: 100%;
}

.btn.darkBlue{
  background-color: var(--DarkBlue) !important;
  color:var(--White) !important;
}

.menuReportsAdmin{
  visibility: visible;
}

.menuReportsUser{
  visibility: visible;
}

/* Main Framework */
.topBar .ui.menu{
    border-radius:0 !important;
}

.topBar .ui.menu .item::before {
  width: 0;
}

.btnHome {
    background: none;
    width: 300px;
}

.btnHome img {
  width: 40px;
  float: left;
  margin-left: -10px;
}

.btnHome h3{
    margin-top: 6px;
    font-size: 20px;
}

.mainMenu .item{
	margin-left:20px;
}

.ui.dimmer {
  background-color: rgba(255,255,255,.40) !important;
}

.ui.dimmer .ui.loader:before {
  border-color: rgba(51, 51, 51, 0.15) !important;
}

.ui.dimmer .ui.loader:after {
  border-color: var(--DarkestPurple) transparent;
}

.ui.primary.buttons .button, .ui.primary.button {
    background-color: var(--DarkestPurple) !important;
}

.ui.violet.buttons .button, .ui.violet.button, .ui.blue.button  {
    background-color: var(--DarkestPurple)  !important;
}

.ui.modal > .close {
  color: #000 !important;
  top: 0 !important;
  right: 0 !important;
}

i.violet.window.close.icon {
  font-size: 1.75em;
  top: -1.5rem;
  right: -1.5rem;
  color: var(--DarkestPurple) !important;
}

button.ui.basic.button.notificationCloseButton {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

/* Dashboard UI */
.Dashboard {
  background-color: var(--LighterGrey) !important;
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.Dashboard .ui.segment.Chart_1 {
  margin-top: -10px;
  width: 1130px;
}

.Dashboard .ui.segment.Chart_2 {
  margin-top: -20px !important;
  margin-left: -15px;
  height: 355px;
}

.Dashboard .ui.segment.Chart_3 {
  margin-left: 20px;
  margin-top: -20px;
  height: 356px;
  width: 580px;
}

/* Main UI */
.UIMain {
  background-color: var(--LighterGrey) !important;
	width: 100% !important;
}

.ui.attached.segment.active.tab.mainForm {
  margin-top: 50px !important;
  border:solid 1px #ccc !important;
  box-shadow: none;
  border-radius: 0px 15px 15px 15px;
  margin-left: 15px;
  z-index:99;
  padding-bottom: 100px;
}

.mainFormWrappper{
  background-color: var(--LightGrey) !important;
  padding: 30px;
}



.interfaceNavigationBar {
  position:absolute !important;
  left:0px !important;
  top:1px !important;
  width: 101%;
  border-top: 1px solid #D4D4D5 !important;
}


.ui.buttons.interfaceNavigationBar {
  border: none;
  box-shadow: none;
  z-index:99;
  background-color: var(--DarkestPurple);
  width: 101%;
  display: inline-block;
}

.ui.buttons.interfaceNavigationBar .ui.button.interfaceNavigationItem{
  background-color:transparent;
  color:#fff;
  text-align: left;
}


.dynamicUI .field{
  margin-right:10px !important;
  padding:5px !important;
}

.dynamicUI label {
  display: block;
  margin: 0 0 .28571429rem;
  color: rgba(0,0,0,.87);
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none;
}

.dynamicUI .ui.selection.dropdown, .dynamicUI .ui.input, .dynamicUI textarea {
  width:100% !important;
}



.react-datepicker__input-container input {
  margin: 0em;
  max-width: 100%;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.progress {
  margin-top: 0px !important;
}

.ui.progress .bar{
  background: var(--DarkestPurple) !important;
}

.baseHeader {
	border: solid 1px #ccc;
  background: rgba(255,255,255,0.8);
  line-height: 40px;
  margin-bottom: 5px;
}

.baseHeader .list{
  text-align: center;
}

.baseHeader .item.first{
  float:left !important;
}

.baseHeader .ui.horizontal.list:not(.celled) > .item:first-child.first {
  margin-left: 2px !important;
}

.baseHeader .item:last-child{
  margin-right:2px !important;
  float:right !important;
}

.btnSmartID {
  background:none !important;
  color: var(--DarkestPurple) !important;
  margin-left: -15px !important;
  margin-top: -2px !important;
  font-size: 15px !important;
}

.btnSmartLookup{
  margin-top: 20px !important;
  margin-left: -15px !important;
  box-shadow: none !important;
}

.ui.labeled.icon.buttons .button.btnOpenNotification,
.ui.labeled.icon.button.btnOpenNotification {
  background:none !important;
  padding: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin: 0 0 0 10px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  flex-basis: 10%;
}

.ui.labeled.icon.buttons .button.btnOpenNotification i,
.ui.labeled.icon.button.btnOpenNotification i  {
      background:none !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      position: initial !important;
      width: 100%;
}

.smartIDViz {
    padding-bottom: 10px;
    border-bottom: dashed 1px #ccc;
    height: 40px;
}

.smartIDViz h3 {
  font-size: 1.28571429rem !important;
  display: inline !important;
  padding-right:20px !important;
}

.SmartIDForm {
  padding-top:20px;
}

.notificationListSidebar {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.notificationListSidebar .notificationList {
  margin-top:35px !important;
  padding: 10px !important;
}

.notificationListSidebar .notificationList .content {
  border-top: dashed 1px #ccc;
  padding-top:10px !important;
}

.ui.compact.fluid.vertical.eventSideBar.menu {
  position: absolute;
  z-index: 99;
  left: 29px;
  top: 64px;
  padding: 0px;
  min-width: 238px;
  border-radius: 15px 0px 0px 15px !important;
  border-right: none;
  box-shadow: none;
}

.ui.compact.fluid.vertical.eventSideBar.menu .item {
  color:var(--DarkGrey) !important;
  border-right:none !important;
}

.ui.compact.fluid.vertical.eventSideBar.menu .item:first-child{
  border-radius: 15px 0px 0px 0px !important;
}

.ui.compact.fluid.vertical.eventSideBar.menu .item:last-child{
  border-radius: 0px 0px 0px 15px !important;
}

.ui.compact.fluid.vertical.eventSideBar.menu .item:hover {
  color: var(--DarkestPurple) !important;
}

.ui.compact.fluid.vertical.eventSideBar.menu .active.item {
  background-color:var(--DarkestPurple);
  color: var(--White) !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


.tblReports .rt-th.rt-resizable-header,
.tblUsers .rt-th.rt-resizable-header,
.tblSiteAdmins .rt-th.rt-resizable-header,
.tblProvider .rt-th.rt-resizable-header,
.tblNotifications .rt-th.rt-resizable-header {
  font-weight: 700;
  text-transform: capitalize;
  text-align: left;
}

.tblBase .rt-th.rt-resizable-header,
.tblBaseRecord .rt-th.rt-resizable-header {
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
}

/* Adding to highlite control that affect compeletion level. */
.score-control .ui.selection.dropdown, .score-control .ui.input > input{
  box-shadow: 0 0 3px rgba(80,17,135, .5);
  border: 1px solid rgba(80,17,135, .5);
}

/* Dynamic Form valid/invalid fields */

.invalidField div.ui.input {
  border: 2px solid #ff0000;
  border-radius: 7px;
}


@media screen and (max-width: 630px) {
  .ui.basic.segment {
    width: 380px !important;
    margin-left: 3%;
  }

  .ui.dividing.header {
    font-size: 22px !important;
  }
}


.ui.vertical.menu .item > i.icon {
  width: 1.18em;
  float: none;
  margin: 0 0.5em 0 0.5em;
}

.sidebar {
  margin-left: 15px;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  overflow: visible;
  white-space: normal
}

.wrapper {
  overflow: visible
}

.wrapper > div.container {
  overflow: visible;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  z-index: 2;
}

.ui.uncover.sidebar {
  transform: none !important;
}

.ui.input.authCodeInput input {
  text-align: center;
}

/* .Toastify {
  position: fixed;
  bottom: 1rem !important;
  righrt: 1rem !important;
} */

#pin-input {
  color: #3D1E72 !important;
}
